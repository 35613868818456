import React from 'react'
import './NotFound.css'

const NotFound = () => {

  return (
    <div className='page-erro'>
        <h1>404</h1>
        <h2>Projeto sem deploy</h2>
    </div>
  )
}

export default NotFound