import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
        <p>Copyright &copy; Wallison 2023</p>
    </footer>
  )
}

export default Footer