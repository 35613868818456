import { useState, useEffect, useReducer } from "react";
import { db } from "../firebase/Api";
import { collection, addDoc, Timestamp } from "firebase/firestore";

//estado inicial
const initialState = {
    loading: null,
    error: null
}

const insertReducer = (state, action) => {

    switch(action.type){
        case "LOADING":
            return {loading: true, error: null}
        case "INSERTED_DOC":
            return {loading: false, error: null}
        case "ERROR":
            return {loading: false, error: action.payload}
        default:
            return state
    }

}

export const useInsertDocument = (docCollection) =>{

    const [response, dispatch] = useReducer(insertReducer, initialState);

    //memory leak
    const [cancelled, setCancelled] = useState(false);

    const checkCancelBeforeDispatch = (action) => {
        if(!cancelled){
            dispatch(action)
        }
    }

    const insertDocument = async (document) => {

        checkCancelBeforeDispatch({
            type: 'LOADING'
        })

        try {
            
            const newDocument = {...document, createdAt: Timestamp.now()}

            const insertedDocument = await addDoc(collection(db, docCollection), newDocument)

            checkCancelBeforeDispatch({
                type: 'INSERTED_DOC',
                payload: insertedDocument,
            })

        } catch (error) {
            checkCancelBeforeDispatch({
                type: 'INSERTED_DOC',
                payload: error.mesage,
            })

        }

    }

    useEffect(() => {
        return () => setCancelled(true);
    }, []);

    return {
        insertDocument,
        response
    }
}